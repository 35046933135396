import { graphql } from "gatsby"
import { AboutPage } from "@pageTemplates"

export default AboutPage

export const pageQuery = graphql`
  query About {
    aboutJson {
      hero {
        image {
          src {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
            publicURL
          }
          alt
        }
      }
      summary
      headshotImage {
        src {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
        alt
      }
      description
      mission {
        title
        body
      }
      valuesCards {
        color
        text
        image {
          src {
            publicURL
          }
          alt
        }
        anchorLinkId
      }
      staff {
        title
        staffMembers {
          name
          position
        }
      }
      fields {
        slug
      }
    }
  }
`
